import { Footer } from 'components/Footer'
import { Nav } from 'components/Nav'
import { PageBase } from 'pages/PageBase'
import React from 'react'

import styles from './index.module.scss'


export class GalleryPage extends PageBase {

  constructor(props: any) {
    super(props)
  }

  render() {
    return (
      <>
        <header className="">
          <Nav/>

          {/* <!-- Hero Content --> */}
          <div className="relative bg-primary-dark">
            <div className="inset-y-0 top-0 right-0 w-full h-full lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
              <img src="assets/images/gallery/Gallery-2.jpg" alt="" className="inset-y-0 top-0 left-0 object-cover w-full h-full max-h-96 lg:max-h-screen" />
            </div>
            <div className="relative py-12 lg:py-24 fluid-container bg-primary-dark header-clip lg:min-h-[400px] flex flex-col justify-center">
              <div className="max-w-xl lg:w-2/5">
                <h1 className="text-3xl font-bold text-white md:text-5xl">Gallery</h1>
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" className="absolute bottom-0 w-full h-4 -mb-1 text-white fill-current md:h-8" viewBox="0 0 650 40">
              <use xlinkHref="#wave"></use>
            </svg>
          </div>

        </header>

        {/* <!-- Main Content Begins--> */}
        <main>
            {/* <!-- Gallery Section Block --> */}
            <section className="my-16">
            <div className="grid items-start max-w-screen-xl gap-8 fluid-container lg:grid-cols-3 md:grid-cols-2">
             
              <a data-lightbox href="assets/images/gallery/Gallery-2.jpg" className="block aspect-w-16 aspect-h-9">
                <img src="assets/images/gallery/Gallery-2.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
              </a>
              <a data-lightbox href="assets/images/gallery/Gallery-3.jpg" className="block aspect-w-16 aspect-h-9">
                <img src="assets/images/gallery/Gallery-3.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
              </a>
              <a data-lightbox href="assets/images/gallery/Gallery-4.jpg" className="block aspect-w-16 aspect-h-9">
                <img src="assets/images/gallery/Gallery-4.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
              </a>
              <a data-lightbox href="assets/images/gallery/Gallery-5.jpg" className="block aspect-w-16 aspect-h-9">
                <img src="assets/images/gallery/Gallery-5.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
              </a>
              <a data-lightbox href="assets/images/gallery/Gallery-6.jpg" className="block aspect-w-16 aspect-h-9">
                <img src="assets/images/gallery/Gallery-6.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
              </a>
              <a data-lightbox href="assets/images/gallery/Gallery-7.jpg" className="block aspect-w-16 aspect-h-9">
                <img src="assets/images/gallery/Gallery-7.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
              </a>
              <a data-lightbox href="assets/images/gallery/Gallery-9.jpg" className="block aspect-w-16 aspect-h-9">
                <img src="assets/images/gallery/Gallery-9.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
              </a>
              <a data-lightbox href="assets/images/gallery/Gallery-11.jpg" className="block aspect-w-16 aspect-h-9">
                <img src="assets/images/gallery/Gallery-11.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
              </a>
              <a data-lightbox href="assets/images/gallery/Gallery-14.jpg" className="block aspect-w-16 aspect-h-9">
                <img src="assets/images/gallery/Gallery-14.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
              </a>
              <a data-lightbox href="assets/images/gallery/Gallery-17.jpg" className="block aspect-w-16 aspect-h-9">
                <img src="assets/images/gallery/Gallery-17.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
              </a>
              <a data-lightbox href="assets/images/gallery/Gallery-18.jpg" className="block aspect-w-16 aspect-h-9">
                <img src="assets/images/gallery/Gallery-18.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
              </a>
              
            </div>
          </section>
        </main>
        
      </>
    )
  }

}
