import { Footer } from "components/Footer";
import { Nav } from "components/Nav";
import { PageBase } from "pages/PageBase";
import React from "react";

import styles from "./index.module.scss";

export class ClinicalCarePage extends PageBase {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <>
        <header className="">
          <Nav />

          {/* <!-- Hero Content --> */}
          <div className="relative bg-primary-dark">
            <div className="inset-y-0 top-0 right-0 w-full h-full lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
              <img
                src={"assets/images/Marne-Street-Hospital_0002.jpg"}
                alt=""
                className="inset-y-0 top-0 left-0 object-cover w-full h-full max-h-96 lg:max-h-screen"
              />
            </div>
            <div className="relative py-12 lg:py-24 fluid-container bg-primary-dark header-clip lg:min-h-[400px] flex flex-col justify-center">
              <div className="max-w-xl lg:w-2/5">
                <h1 className="text-3xl font-bold text-white md:text-5xl">
                  Clinical Care
                </h1>
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              className="absolute bottom-0 w-full h-4 -mb-1 text-white fill-current md:h-8"
              viewBox="0 0 650 40"
            >
              <use xlinkHref="#wave"></use>
            </svg>
          </div>
        </header>
        {/* <!-- Main Content Begins--> */}
        <main>
          {/* <!-- Content Section Block --> */}
         
          <section className="my-16">
            {/* <div className="grid max-w-screen-xl gap-8 md:grid-cols-2 fluid-container md:gap-16"> */}
            <div className="grid max-w-screen-xl gap-8 lg:gap-20 lg:grid-cols-2 grid-rows-1 fluid-container my-8">
               {/* <!-- Primary Column --> */}
                <div className="prose prose-lg max-w-none">
                <h2>The Keys to Marne Street Hospital's Success</h2>
                  <p>
                    Highly skilled staff, top quality medical equipment and a
                    passion for working with the elderly underpin Marne Street
                    Hospital’s exemplary level of service. The medical, social
                    and emotional needs of up to 55 residents are catered for in
                    a family-oriented environment.
                  </p>
                  <p>
                    We accept residents with a variety of clinical conditions or
                    needs requiring hospital level care as assessed by the
                    Te Whatu Ora / Health NZ needs assessment team.
                    These conditions include:
                  </p>
                  <ul>
                    <li>General medical conditions</li>
                    <li>Mild confusion and behavioural problems</li>
                    <li>Tracheostomies</li>
                    <li>
                      Percutaneous Endoscopic Gastrostomy (PEG) and nasogastric
                      tubes
                    </li>
                    <li>Oxygen therapy</li>
                    <li>Palliative care</li>
                    <li>Urinary catheter care</li>
                    <li>Colostomy/ileostomy care</li>
                    <li>Wound care</li>
                  </ul>
                </div>
                {/* column 2 */}
              <div className="w-full p-[13%] mt-6">
                <div className="relative w-full aspect-w-4 aspect-h-3">
                  <div className="absolute w-full">
                    <div className="block aspect-w-5 aspect-h-3">
                      <div className="block bg-black/10 rounded-2xl">
                      </div>
                    </div>
                  </div>
                  <div className="absolute w-full transform translate-x-[-20%] translate-y-[-20%]">
                    <div className="block aspect-w-5 aspect-h-3">
                      <img src="assets/images/Marne-Street-Hospital_0063.jpg" alt="" className="object-cover shadow-2xl rounded-2xl bg-primary" />
                    </div>
                  </div>
                  <div className="absolute w-full translate-x-[20%] translate-y-[20%]">
                    <div className="block aspect-w-5 aspect-h-3">
                      <img src="assets/images/Marne-Street-Hospital-Medication-Trolley.jpg" alt="" className="object-cover shadow-2xl rounded-2xl bg-primary" />
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div className="prose-lg px-8 fluid-container max-w-screen-xl"> 
              <p>
                      To assist with this care our staff includes a facility
                      manager, a clinical manager, two house doctors (under
                      contract to Marne Street Hospital), registered nurses (on
                      duty at all times), enrolled nurses and health care assistants,
                      a physiotherapist, a diversional therapist, an infection
                      control nurse, a restraint management nurse and a
                      continence co-ordinator.
                    </p>
                    <p>
                      However, when required, we have access to Hospice staff
                      (nurses and family support team), a speech language
                      therapist, a dietician, a podiatrist, a nurse practitioner
                      for older person’s mental health, a wound care specialist,
                      a wound care product advisor, social workers, chaplaincy
                      services and cultural support personnel. A referral system
                      helps ensure problems are identified early and quickly
                      resolved.
                    </p>
                    <p>
                      Marne Street Hospital is equipped with a full range of
                      top-grade equipment to meet the needs of most situations,
                      including roof and free-standing hoists, so residents who
                      are not mobile are able to be easily, safely and
                      painlessly moved. All nursing staff are trained by our
                      resident physiotherapist to ensure they safely move
                      residents and to assist them in looking after their own
                      physical welfare.
                    </p>
                    <p>
                      If you have a relative at Marne Street Hospital, you can
                      be sure that we will do everything in our power to make
                      their time with us as comfortable as possible.
                    </p>
              </div>
        
          </section>
        </main>

        
      </>
    );
  }
}
