import { Footer } from 'components/Footer'
import { Nav } from 'components/Nav'
import { PageBase } from 'pages/PageBase'
import React from 'react'

import styles from './index.module.scss'


export class MovingIntoCarePage extends PageBase {

  constructor(props: any) {
    super(props)
  }

  render() {
    return (
      <>
        <header className="">
          <Nav/>

          {/* <!-- Hero Content --> */}
          <div className="relative bg-primary-dark">
            <div className="inset-y-0 top-0 right-0 w-full h-full lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
              <img src={"assets/images/Marne-Street-Hospital_0003.jpg"} alt="" className="inset-y-0 top-0 left-0 object-cover w-full h-full max-h-96 lg:max-h-screen" />
            </div>
            <div className="relative py-12 lg:py-24 fluid-container bg-primary-dark header-clip lg:min-h-[400px] flex flex-col justify-center">
              <div className="max-w-xl lg:w-2/5">
                <h1 className="text-3xl font-bold text-white md:text-5xl">Moving into Care</h1>
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" className="absolute bottom-0 w-full h-4 -mb-1 text-white fill-current md:h-8" viewBox="0 0 650 40">
              <use xlinkHref="#wave"></use>
            </svg>
          </div>

        </header>
        {/* <!-- Main Content Begins--> */}
        <main>
          {/* <!-- FAQ Content Section Block --> */}
          <section className="my-16">
            <div className="grid max-w-screen-xl gap-8 md:grid-cols-5 fluid-container md:gap-16">
              {/* <!-- Primary Column --> */}
              <div className="md:col-span-3">
                <div className="prose ">
                  <p>We know just how overwhelming it can be when someone you love is in need of residential care</p>
                  <h2>Step 1: Organise a Needs Assessment</h2>
                  <div className="md:ml-8">
                    <p>If you feel like it’s time for you or a loved one to receive greater care and attention, the first step is to organise a needs assessment with your local Care Coordination Centre (CCC). This can be organised by your doctor, your hospital or if you wish you can contact the Te Whatu Ora / Health NZ directly and ask to speak to a needs assessment coordinator.</p>
                    <p>The purpose of CCC is to determine whether your close friend or family member is eligible for hospital level care. Anyone can enter residential care of their own volition, but if you wish to receive disability support from your Te Whatu Ora / Health NZ, then it’s essential to undergo a needs assessment.</p>
                    <p>Your family member is eligible for long-term care, if:</p>
                    <ul>
                      <li>They have high or very high needs;</li>
                      <li>Their condition is irreversible;</li>
                      <li>and	they cannot be safely supported within the community.</li>
                    </ul>
                    <p>
                    To make an appointment with CCC you can either get a referral from your GP or hospital, phone your local DHB and ask to speak to a local CCC organisation, or choose your local CCC online by checking the list on the <a href="https://www.health.govt.nz/" target="_blank">Ministry of Health website</a>, or by going directly to <a href="https://www.nznasca.co.nz/services/older-peoples-nasc-services/" target="_blank">CCC</a>.
                    </p>
                    <p>
                    <a href="https://www.health.govt.nz/"     target="_blank">Visit the Ministry of Health website »</a><br/>
                    </p>
                    <h2>Step 2: Complete Needs Assessment</h2>
                    <p>
                    Once an appointment has been made with CCC, a needs assessor will visit your home to identify and discuss the specific health requirements of your loved one. This is a necessary step to complete the needs assessment and may include a specialist geriatric assessment. If your family member or close friend is already hospitalised, the hospital can arrange a needs assessment in the ward.
                    </p>
                    <p>
                    Once CCC finds your loved one to be eligible to enter a hospital level care facility, you will be given a financial means application to apply for public funding.
                    </p>

                    <h2>Step 3: Create a Plan of Action</h2>
                    <p>
                    After completing the needs assessment, the CCC will help you devise a plan of action to facilitate the commencement of hospital level care at Marne Street Hospital.
                    </p>

                    <h2>Step 4: Choosing Marne Street Hospital</h2>
                    <p>
                    We understand placing a loved into Marne Street Hospital is not an easy thing to do and is often very stressful. After all, you are entrusting the duty of care for your loved one to Marne Street Hospital and naturally you will have concerns over the safe care of you loved one.
                    </p>
                    <p>
                    The Ministry of Health conducts regular audits to ensure all Aged Residential Care Hospitals meet the standards of the Health and Disability Services (Safety) Act 2001.
                    </p>
                    <p>
                    You can review Marne Street Hospitals audits <a href="https://www.health.govt.nz/audits"     target="_blank">here</a>
                    </p>
                    <p>
                    Where possible make the decision on placing your loved one at Marne Street in consultation with your loved one.
                    </p>
                    <p>
                    However, more often than not, special care is urgent and there is not much time, so our loved one is unable to visit Marne Street Hospital themselves.
                    </p>
                    <p>
                    When making a decision on behalf of a family member, be sure to make an appointment to speak to the Facility Manager, who is there to help you answer all your questions. Before you visit, it’s best to write down any questions or concerns you may have. You may have questions relating to:
                    </p>
                    <ul>
                      <li>Management</li>
                      <li>Extra costs (additional costs not </li>subsidised by the daily DHB rate)
                      <li>Meals and other service-related care</li>
                      <li>Rooms, grounds and buildings,</li>
                      <li>Rules</li>
                      <li>Activities</li>
                      <li>Complaints 	procedures</li>
                    </ul>
                    <h2>Step 5: Sign the Admission Agreement</h2>
                    <p>Before moving into Marne Street Hospital, an admission agreement or contract needs to be signed.</p>
                    <p>The purpose of the admission agreement is to protect the resident and Marne Street Hospital by setting out the responsibilities and expectations of both parties.</p>
                    <p>The admission agreement is a legal document that specifies such things as extra services you have agreed to receive and pay for, costs and extra charges, liability for damage or loss of belongings of the resident, resident safety, transport, procedures, and complaint processes. Should you wish to discuss or negotiate any aspects of the admissions agreement with Marne Street Hospital, you may choose to have a nominated power of attorney or lawyer support you.</p>
                    <p>Once the admission agreement has been signed, your loved one is able to move into Marne Street Hospital.</p>
                    <p>You can now rest assured that they’re in safe hands and well looked after.</p>

                    <h2>Step 6: Apply for a Subsidy or Loan</h2>
                    <p>After the CCC assessment, you can apply for a financial means assessment straight away.</p>
                    <p>All applications for a financial means assessment will be made to Work and Income. The purpose of a financial means assessment is to determine whether the applicant qualifies for public funding through a Residential Care Subsidy or a Residential Care Loan.</p>
                    <p>We advise you to apply for a financial means assessment on behalf of your loved one as soon as possible, even if you cannot supply Work and Income with all the necessary papers immediately.</p>
                    <p>You will be required to pay for residential care, until it has been confirmed by Work and Income that you qualify for a Residential Care Subsidy/Loan. If your family member or close friend has entered residential care on his or her own accord, without going through CCC first, then they are personally liable to pay for the full cost.</p>
                    <p>More information on Residential Care Subsidy and Residential Care Loan can be found &nbsp;
                      <a href="https://www.workandincome.govt.nz/products/a-z-benefits/residential-care-subsidy.html" target="_blank">here.</a>
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </section>


        </main>

        
      </>
    )
  }

}
