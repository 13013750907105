import { ContactForm } from 'components/ContactForm'
import { Nav } from 'components/Nav'
import { PageBase } from 'pages/PageBase'


export class CareersPage extends PageBase {

  constructor(props: any) {
    super(props)
  }

  render() {
    return (
      <>
        <header className="">
          <Nav/>

          {/* <!-- Hero Content --> */}
          <div className="relative bg-primary-dark">
            <div className="inset-y-0 top-0 right-0 w-full h-full lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
              <img src="assets/images/Marne-Street-Hospital_0005.jpg" alt="" className="inset-y-0 top-0 left-0 object-cover w-full h-full max-h-96 lg:max-h-screen" />
            </div>
            <div className="relative py-12 lg:py-24 fluid-container bg-primary-dark header-clip lg:min-h-[400px] flex flex-col justify-center">
              <div className="max-w-xl lg:w-2/5">
                <h1 className="text-3xl font-bold text-white md:text-5xl">Careers</h1>
                <div className="pt-4 prose text-white md:prose-lg">
                  <p>Marne Street Hospital’s workforce covers a variety of positions. </p>
                </div>
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" className="absolute bottom-0 w-full h-4 -mb-1 text-white fill-current md:h-8" viewBox="0 0 650 40">
              <use xlinkHref="#wave"></use>
            </svg>
          </div>

        </header>

        <main>
          <section className="my-16">
            <div className="grid max-w-screen-xl gap-8 md:grid-cols-1 fluid-container md:gap-16">

              <div className="prose prose-lg px-8 fluid-container max-w-screen-xl">
                <p>Marne Street Hospital’s workforce covers a variety of positions. These include:</p>
                <ul>
                  <li>Nurses</li>
                  <li>Health Care Assistants</li>
                  <li>Kitchen Staff</li>
                  <li>Cleaners</li>
                  <li>Laundry</li>
                  <li>Maintenance</li>
                  <li>Diversional Therapists</li>
                  <li>Volunteers</li>
                </ul>
                <p>If you are interested in one of these positions and you are a team player, hardworking, honest and a compassionate person who wants to be part of a strong team, we may have a position available for you.</p>
                 <p> Please email us and we will get back to you as soon as possible: <span> <a className="text-blue-900 transition hover:text-secondary-light" href="mailto:manager@marnestreet.co.nz">manager@marnestreet.co.nz</a></span></p>
              </div>

              {/* <!-- Secondary Column --> */}
              {/* <div>
                <ContactForm />
              </div> */}
            </div>
          </section>
        </main>
        
      </>
    )
  }

}
