import { Footer } from 'components/Footer'
import { Nav } from 'components/Nav'
import { PageBase } from 'pages/PageBase'
import React from 'react'
import { NavLink } from 'react-router-dom'

export class AboutUsPage extends PageBase {

  constructor(props: any) {
    super(props)
  }

  render() {
    return (
      <>
        <header className="">
          {/* <!-- Navigation Bar --> */}
          <Nav/>

          {/* <!-- Hero Content --> */}
          <div className="relative bg-primary-dark">
            <div className="inset-y-0 top-0 right-0 w-full h-full lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
              <img src="/assets/images/Marne-Street-Hospital_0001.jpg" alt="" className="inset-y-0 top-0 left-0 object-cover w-full h-full max-h-96 lg:max-h-screen" />
            </div>
            <div className="relative py-16 lg:py-48 fluid-container bg-primary-dark header-clip lg:min-h-[400px] flex flex-col justify-center -mt-16 md:-mt-0">
              <div className="max-w-xl lg:w-2/5">
                <h1 className="relative text-4xl font-bold text-white md:text-6xl">Residential <br/>Hospital Care<img src="/assets/images/marne-logo-accent.svg" alt="" className="absolute w-32 md:w-42 left-48 md:left-80 -top-12 md:-top-16"/>
                </h1>
                <div className="mt-2 text-2xl md:text-4xl text-secondary-light">in a home-like environment</div>
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" className="absolute bottom-0 w-full h-4 -mb-1 text-white fill-current md:h-8" viewBox="0 0 650 40">
              <use xlinkHref="#wave"></use>
            </svg>
          </div>

          {/* <!-- Homepage Nav Feature --> */}
          <div className="relative max-w-screen-xl lg:-mb-16 top-8 lg:-top-16 fluid-container">
            <div className="relative grid overflow-hidden font-sans divide-x-2 divide-y shadow-2xl lg:divide-y-none md:grid-cols-2 xl:grid-cols-4 divide-white/10 bg-secondary rounded-2xl">
              <NavLink exact to="/moving-into-care" className="flex flex-row items-center p-4 px-8 text-white transition lg:p-6 hover:bg-primary group" href="">
                <img src="/assets/images/icon-rightarrow.svg" alt="" className="w-10 h-10 mr-4 transition opacity-25 group-hover:opacity-50 group-hover:scale-110" />
                <div>
                  <div className="leading-tight opacity-75">Admissions</div>
                  <div className="text-xl font-bold">Moving into Care</div>
                </div>
              </NavLink>
              <NavLink exact to="/facilities" className="flex flex-row items-center p-4 px-8 text-white transition lg:p-6 hover:bg-primary group" href="">
                <img src="/assets/images/icon-hospital.svg" alt="" className="w-10 h-10 mr-4 transition opacity-25 group-hover:opacity-50 group-hover:scale-110" />
                <div>
                  <div className="leading-tight opacity-75">Learn About</div>
                  <div className="text-xl font-bold">Our Facilities</div>
                </div>
              </NavLink>
              <NavLink exact to="/faq" className="flex flex-row items-center p-4 px-8 text-white transition lg:p-6 hover:bg-primary group">
                <img src="/assets/images/icon-question.svg" alt="" className="w-10 h-10 mr-4 transition opacity-25 group-hover:opacity-50 group-hover:scale-110" />
                <div>
                  <div className="leading-tight opacity-75">Frequently Asked</div>
                  <div className="text-xl font-bold">Questions</div>
                </div>
              </NavLink>
              <NavLink exact to="/contact" className="flex flex-row items-center p-4 px-8 text-white transition lg:p-6 hover:bg-primary group">
                <img src="/assets/images/icon-people.svg" alt="" className="w-10 h-10 mr-4 transition opacity-25 group-hover:opacity-50 group-hover:scale-110" />
                <div>
                  <div className="text-xl font-bold">Contact Us</div>
                </div>
              </NavLink>
            </div>
          </div>
        </header>

        {/* <!-- Main Content Begins--> */}
        <main>

          {/* <!-- Home Feature Content --> */}
          <section className="my-16">
            <div className="grid max-w-screen-xl gap-8 lg:gap-20 lg:grid-cols-2 fluid-container">
              <div>
                <div className="text-3xl font-bold leading-snug text-primary">Marne Street Hospital is a modern, fully equipped provider of full hospital-level residential care.</div>
                <img src="/assets/images/accent-squiggle.svg" alt="" className="my-8" />
                <div className="prose prose-lg max-w-none">
                  <p>Centrally located, adjacent to a park on Bayfield Inlet, Andersons Bay, Dunedin, Marne Street Hospital prides itself on its family atmosphere, quality service, high staff-resident ratio and excellence in professional care.</p>
                  <p>This website sets out to answer some of the questions you will have if a member of your family needs ongoing support in an aged care environment.</p>
                </div>
              </div>

              <div className="w-full p-[13%] mt-6">
                <div className="relative w-full aspect-w-4 aspect-h-3">
                  <div className="absolute w-full">
                    <div className="block aspect-w-5 aspect-h-3">
                      <div className="block bg-black/10 rounded-2xl">
                      </div>
                    </div>
                  </div>
                  <div className="absolute w-full transform translate-x-[-20%] translate-y-[-20%]">
                    <div className="block aspect-w-5 aspect-h-3">
                      <img src="assets/images/Marne-Street-Hospital_0005.jpg" alt="" className="object-cover shadow-2xl rounded-2xl bg-primary" />
                    </div>
                  </div>
                  <div className="absolute w-full translate-x-[20%] translate-y-[20%]">
                    <div className="block aspect-w-5 aspect-h-3">
                      <img src="assets/images/Marne-Street-Hospital_0072.jpg" alt="" className="object-cover shadow-2xl rounded-2xl bg-primary" />
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div className="prose-lg my-8 px-8 fluid-container max-w-screen-xl">
              <h2>Mission Statement</h2>
              <p>
              In partnership we are committed to providing the highest quality holistic care and services for our residents, which exceed their expectations.
              </p>
              <h2>Vision and Values</h2>
              <h3>Respect</h3>
              <p>For the dignity, beliefs and abilities of every individual. Showing tolerance, recognising individual needs and treating others as equals.</p>
              <h3>Caring and Compassion</h3>
              <p>Showing empathy and support, and acknowledging the needs of others.</p>
              <h3>Integrity</h3>
              <p>Being honest, fair, just, reasonable and ethical.</p>
              <h3>Unity</h3>
              <p>Achieving success by working together as a team, valuing each others skills and contributions.</p>
              <h3>Discovery</h3>
              <p>Through passion for innovation, constantly seeking and striving for improvement and professional development.</p>
              </div>
          </section>
          {/* <!-- Gallery Section Block --> */}
          <section className="my-16">
            <div className="grid items-start max-w-screen-xl gap-8 fluid-container lg:grid-cols-3 md:grid-cols-2">
            <a data-lightbox href="assets/images/Marne-Street-Hospital_0017.jpg" className="block aspect-w-16 aspect-h-9">
                <img src="assets/images/Marne-Street-Hospital_0017.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
              </a>
              <a data-lightbox href="assets/images/Marne-Street-Hospital_0030.jpg" className="block aspect-w-16 aspect-h-9">
                <img src="assets/images/Marne-Street-Hospital_0030.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
              </a>
              <a data-lightbox href="assets/images/Marne-Street-Hospital_0031.jpg" className="block aspect-w-16 aspect-h-9">
                <img src="assets/images/Marne-Street-Hospital_0031.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
              </a>
            </div>
          </section>
        </main>

      
      </>
    )
  }

}
