
import { Nav } from 'components/Nav'
import { PageBase } from 'pages/PageBase'

type ManualStyles = {
  marginBottom: string;
}

//set margin value to override tailwind 
const marginBottom = "6em";


export class OurTeamPage extends PageBase {

  constructor(props: any) {
    super(props)
  }

  render() {
    return (
      <>
        <header className="">
          <Nav />

          {/* <!-- Hero Content --> */}
          <div className="relative bg-primary-dark">
            <div className="inset-y-0 top-0 right-0 w-full h-full lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
              <img src={"assets/images/Marne-Street-Hospital_0003.jpg"} alt="" className="inset-y-0 top-0 left-0 object-cover w-full h-full max-h-96 lg:max-h-screen" />
            </div>
            <div className="relative py-12 lg:py-24 fluid-container bg-primary-dark header-clip lg:min-h-[400px] flex flex-col justify-center">
              <div className="max-w-xl lg:w-2/5">
                <h1 className="text-3xl font-bold text-white md:text-5xl">Our Team</h1>
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" className="absolute bottom-0 w-full h-4 -mb-1 text-white fill-current md:h-8" viewBox="0 0 650 40">
              <use xlinkHref="#wave"></use>
            </svg>
          </div>

        </header>
        {/* <!-- Main Content Begins--> */}
        <main>

          <section className="my-16">
            <div className="prose-lg px-8 fluid-container max-w-screen-xl">
              <div className="prose prose-lg max-w-none">
                <h2>Who We Are</h2>
              </div>
              <p>
                Marne Street Hospital is staffed by a team of 60, made up of management,
                medical and support staff. We have qualified nurses on duty at all times and in-house doctors who are experienced in aged care.
              </p>
              <p>Key members of our staff include:</p>
            </div>
            <div className="flex flex-col px-8 mt-8 fluid-container max-w-screen-xl ">
              <div className="prose-lg flex-initial text-left ">
                {/* Staff profile content */}
                <h3>Eldo Raju - Facility Manager</h3>
              <img src="assets/images/Eldo-Raju.jpeg" alt="" className="max-w-[300px] mt-[2em] rounded-xl " />
                <p>
                I have moved back to be in Dunedin after several years away in the North Island. I have been in the New Zealand Aged Care Sector for a number of years.  
                My journey started as a Registered Nurse in general nursing and then in acute mental health.  
                From here I moved in to management, initially as a Supervising Clinical Registered Nurse and from here worked my way through the ranks to the role of Facility Manager in Northland. 
                </p>
                <p>
                From my previous roles I have gained strong leadership and people management skills as well as a good understanding of the challenges faced within the New Zealand Aged Care sector.</p>
                <p>
                  "I am excited by both being back in Dunedin and the opportunity to manage an excellent facility like Marne Street Hospital, where the team share my passion for high quality resident care."
                </p>
              </div>
            </div>
            <div className="flex flex-col px-8 fluid-container max-w-screen-xl mt-12 ">
              <div className="prose-lg flex-initial text-left mt-6 ">
                {/* Staff profile content */}
                <h3>Shona Butler - Clinical Manager</h3>
              <img src="assets/images/Shona-Butler.jpg" alt="" className="max-w-[300px] max-h-[300px] mt-[2em] rounded-xl " />
                <p>
                  Shona has been involved in various aged care facilities in Dunedin over the last 20 years.
                  She has been the senior nurse and assistant manager in hospital level facilities. Her main interests are in palliative care and gerontology nursing.
                </p>
                <p>
                  “I have been at Marne Street Hospital since 2012. I have a passion for care of the elderly and palliative care and aim to continue the great care and respect given to our residents and families by our caring staff.”
                </p>

              </div>
            </div>
            <div className="flex flex-col px-8 fluid-container max-w-screen-xl mt-12">
              <div className="prose-lg flex-initial text-left mt-6 ">
                {/* Staff profile content */}
                <h3>Debbie Wilson- Diversional Therapist</h3>
                <img src="assets/images/Debbie-Wilson.jpg" alt="" className="max-w-[300px] mt-[2em] rounded-xl " />
                <p>
                  Debbie has a Certificate in Diversional Therapy and is responsible for creating the activities programme.
                </p>
                <p>
                  “I have unconditional respect for those coming into Marne Street Hospital and making it their home.
                  Through our activities programme, we encourage and stimulate residents and their families, helping them retain and share their memories and life lessons with younger generations.”
                </p>

              </div>
            </div>
            <div className="flex flex-col px-8 fluid-container max-w-screen-xl mt-12 ">
              <div className="prose-lg flex-initial text-left mt-6 ">
                {/* Staff profile content */}
                <h3>Heather Sue - Receptionist</h3>
              <img src="assets/images/Heather-Sue.jpeg" alt="" className="max-w-[300px] max-h-[300px] mt-[2em] rounded-xl " />
                <p>
                I love my role as receptionist here at Marne Street Hospital. Meeting and getting to know the residents and their families is a highlight for me.<br></br>
                I always strive to make everyone’s time with us a special one.
                Outside of work I can often be found walking my lovely dog Millie, who loves to come for visits to Marne Street Hospital as well.

                </p>

              </div>
            </div>
          </section >
        </main >
      </>
    )
  }

}
