import React from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import {AboutUsPage} from 'pages/AboutUsPage'
import {ClinicalCarePage} from 'pages/ClinicalCarePage'
import {OurTeamPage} from 'pages/OurTeamPage'
import {FamilyPage} from 'pages/FamilyPage'
import {GalleryPage} from 'pages/GalleryPage'
import {FaqPage} from 'pages/FaqPage'
import {CareersPage} from 'pages/CareersPage'
import {ContactPage} from 'pages/ContactPage'
import {FacilitiesPage} from 'pages/FacilitiesPage'
import { MovingIntoCarePage } from 'pages/MovingIntoCarePage'
import { Footer } from 'components/Footer'

@(withRouter as any)
export default class App extends React.Component<any> {

  constructor(props:any) {
    super(props);
  }

  render() {
    return (
      <div className="flex flex-col min-h-screen site-wrapper">
        <Switch>
          <Route    path = '/clinical-care' exact component         = {ClinicalCarePage} />
          <Route    path = '/facilities' exact component         = {FacilitiesPage} />
          <Route    path = '/our-team' exact component               = {OurTeamPage} />
          <Route    path = '/family' exact component                = {FamilyPage} />
          <Route    path = '/gallery' exact component               = {GalleryPage} />
          <Route    path = '/faq' exact component                   = {FaqPage} />
          <Route    path = '/careers' exact component               = {CareersPage} />
          <Route    path = '/contact' exact component               = {ContactPage} />
          <Route    path = '/moving-into-care' exact component = {MovingIntoCarePage} />
          <Route    path = '/' exact component                      = {AboutUsPage} />
          <Redirect to   = '/' />
        </Switch>
        <Footer />
      </div>
    );
  }
}
