import React from 'react'
import GLightbox from 'glightbox';

export class PageBase extends React.Component<any> {

  constructor(props: any) {
    super(props)
  }

  componentDidMount() {
    const lightbox = GLightbox({
      touchNavigation: true,
      selector: 'data-lightbox',
      videosWidth: '80vw'
    });
  }

}
