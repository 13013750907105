import { ContactForm } from "components/ContactForm";
import { Nav } from "components/Nav";
import { PageBase } from "pages/PageBase";


export class ContactPage extends PageBase {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <>
        <header className="">
          {/* <!-- Navigation Bar --> */}
          <Nav />

          {/* <!-- Hero Content --> */}
          <div className="relative bg-primary-dark">
            <div className="inset-y-0 top-0 right-0 w-full h-full lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
              <img
                src="assets/images/Marne-Street-Hospital-Reception-2.jpg"
                alt=""
                className="inset-y-0 top-0 left-0 object-cover w-full h-full max-h-96 lg:max-h-screen"
              />
            </div>
            <div className="relative py-12 lg:py-24 fluid-container bg-primary-dark header-clip lg:min-h-[400px] flex flex-col justify-center">
              <div className="max-w-xl lg:w-2/5">
                <h1 className="text-3xl font-bold text-white md:text-5xl">
                  Contact Us
                </h1>
                {/* <!-- Contact-specific hero block --> */}
                <div className="pt-8 font-sans text-white">
                  <a
                    className="block text-2xl transition hover:text-secondary-light"
                    href="tel:03 454 2680"
                  >
                    03 454 2680
                  </a>
                  <a
                    className="block text-2xl transition hover:text-secondary-light"
                    href="mailto:manager@marnestreet.co.nz"
                  >
                    manager@marnestreet.co.nz
                  </a>
                  <div className="pt-4">
                    7 Marne St, Andersons Bay, Dunedin 9013
                  </div>
                </div>
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              className="absolute bottom-0 w-full h-4 -mb-1 text-white fill-current md:h-8"
              viewBox="0 0 650 40"
            >
              <use xlinkHref="#wave"></use>
            </svg>
          </div>
        </header>

        {/* <!-- Main Content Begins--> */}
        <main>
          {/* <!-- Contact Block --> */}
          <section className="my-16">
            <div className=" max-w-screen-xl  fluid-container ">
              {/* <!-- Primary Column --> */}
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2776.9113848437833!2d170.52664841676605!3d-45.89308537910828!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa82eae820005f883%3A0xe6d2aae65723a831!2sMarne%20Street%20Hospital!5e0!3m2!1sen!2sau!4v1627949913303!5m2!1sen!2sau"
                  className="w-full h-full min-h-[400px] rounded-2xl"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                ></iframe>
            </div>
          </section>
          {/* <!-- Contact Form --> */}
          {/* <div className=" max-w-screen-xl  fluid-container ">
          <ContactForm />
          </div> */}
        </main>
      </>
    );
  }
}
