import React from 'react';
import ReactDOM from 'react-dom';
import "glightbox/dist/css/glightbox.min.css"
import "mburger-css/dist/mburger.css"
import "mmenu-js/dist/mmenu.css"
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';


ReactDOM.render(
  <BrowserRouter basename={"/"}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(process.env.NODE_ENV=="development"?console.log:undefined);
