import { Footer } from "components/Footer";
import { Nav } from "components/Nav";
import { PageBase } from "pages/PageBase";
import React from "react";

import styles from "./index.module.scss";

type ManualStyles ={
marginBottom: string;
}

//set margin value to override tailwind 
const marginBottom = "6em"; 

export class FacilitiesPage extends PageBase {
  constructor(props: any) {
    super(props);
  }

  render() {
   
    return (
      <>
        <header className="">
          <Nav />

          {/* <!-- Hero Content --> */}
          <div className="relative bg-primary-dark">
            <div className="inset-y-0 top-0 right-0 w-full h-full lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
              <img
                src={"assets/images/Marne-Street-Hospital_hill_lounge.jpg"}
                alt=""
                className="inset-y-0 top-0 left-0 object-cover w-full h-full max-h-96 lg:max-h-screen"
              />
            </div>
            <div className="relative py-12 lg:py-24 fluid-container bg-primary-dark header-clip lg:min-h-[400px] flex flex-col justify-center">
              <div className="max-w-xl lg:w-2/5">
                <h1 className="text-3xl font-bold text-white md:text-5xl">
                  Facilities
                </h1>
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              className="absolute bottom-0 w-full h-4 -mb-1 text-white fill-current md:h-8"
              viewBox="0 0 650 40"
            >
              <use xlinkHref="#wave"></use>
            </svg>
          </div>
        </header>
        {/* <!-- Main Content Begins--> */}
        <main>
          {/* <!-- Content Section Block --> */}

          <section className="my-16">
            <div className="prose-lg px-8 fluid-container max-w-screen-xl">
              <div className="prose prose-lg max-w-none">
                  <h2>A Home Away From Home</h2>
                </div>
            <div className="my-12">
            <h3>Resident's rooms</h3>
                <p>
                  The well appointed rooms of Marne Street Hospital, which are
                  all single, have electric beds and some have an adjoining
                  bathroom. We encourage residents to bring with them photos,
                  television sets, stereos, plants and other meaningful
                  memorabilia with which to personalise their room. Large
                  windows look out onto either the streetscape, park or gardens.
                  They have their own reclining chair, on wheels, enabling the
                  less mobile access to all areas of the hospital.
                </p>

             <div className="grid items-start max-w-screen-xl gap-8 fluid-container lg:grid-cols-3 md:grid-cols-1" style={{marginBottom}}>
                  <a data-lightbox href="assets/images/Marne-Street-Hospital_0046.jpg" className="block aspect-w-16 aspect-h-9 ">
                    <img src="assets/images/Marne-Street-Hospital_0046.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl " />
                  </a>
                  <a data-lightbox href="assets/images/Marne-Street-Hospital_0063.jpg" className="block aspect-w-16 aspect-h-9">
                    <img src="assets/images/Marne-Street-Hospital_0063.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
                  </a>
                  <a data-lightbox href="assets/images/Marne-Street-Hospital_0049.jpg" className="block aspect-w-16 aspect-h-9">
                    <img src="assets/images/Marne-Street-Hospital_0049.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
                  </a>
                </div>
              </div>
              <div className="my-12">
                <h3>Resident's lounges</h3>
                <p>
                  Marne Street Hospital has generous sized lounges at either end
                  of the complex. One looks out over the park and nearby inlet,
                  while the second allows residents to see what’s happening on the
                  streets outside. A third, smaller lounge with views of the park,
                  has a kitchenette and is used by palliative care family members
                  who want to be near their loved one. This lounge is also used
                  for family dinners and birthday parties. For entertainment,
                  there are large television screens in each of the lounges and a
                  well-used piano.
                </p>
                {/* <!-- Gallery Section Block --> */}

                <div className="grid items-start max-w-screen-xl gap-8 fluid-container lg:grid-cols-3 md:grid-cols-1" style={{marginBottom}}>
                  <a data-lightbox href="assets/images/Marne-Street-Hospital_0021.jpg" className="block aspect-w-16 aspect-h-9 ">
                    <img src="assets/images/Marne-Street-Hospital_0021.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl " />
                  </a>
                  <a data-lightbox href="assets/images/Marne-Street-Hospital_0037.jpg" className="block aspect-w-16 aspect-h-9">
                    <img src="assets/images/Marne-Street-Hospital_0037.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
                  </a>
                  <a data-lightbox href="assets/images/Marne-Street-Hospital_0006.jpg" className="block aspect-w-16 aspect-h-9">
                    <img src="assets/images/Marne-Street-Hospital_0006.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
                  </a>
                </div>
              </div>
              <div className="my-12">
                <h3>Activities</h3>
                <p>
                  On any given day, Marne Street Hospital is humming with activity. 
                  The hairdresser is in residence weekly, and the hair salon is buzzing. Volunteers bring in
                  dogs to visit residents, and the diversional therapist organises
                  a never-ending range of activities programmes. Scheduled
                  activities include newspaper reading, Housie, quizzes, crafts
                  (including a knitting group), walks in the park, regular church
                  services, Happy Hour, movies and hand pampering sessions. A
                  variety of entertainers visit frequently and events are
                  organised to celebrate special occasions and significant
                  holidays such as Easter and Christmas. A local taxi service
                  provides transportation to various destinations and
                  appointments. And from the surrounding streets buses leave
                  regularly for those able to independently venture a little
                  further.
                </p>

             <div className="grid items-start max-w-screen-xl gap-8 fluid-container lg:grid-cols-3 md:grid-cols-1" style={{marginBottom}}>
                  <a data-lightbox href="assets/images/Marne-Street-Hospital_0017.jpg" className="block aspect-w-16 aspect-h-9">
                    <img src="assets/images/Marne-Street-Hospital_0017.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
                  </a>
                  <a data-lightbox href="assets/images/Marne-Street-Hospital_0042.jpg" className="block aspect-w-16 aspect-h-9 ">
                    <img src="assets/images/Marne-Street-Hospital_0042.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl " />
                  </a>
                  <a data-lightbox href="assets/images/Marne-Street-Hospital_0019.jpg" className="block aspect-w-16 aspect-h-9">
                    <img src="assets/images/Marne-Street-Hospital_0019.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
                  </a>
                </div>
              </div>
              <div className="my-12">
                <h3>Safety</h3>
                <p>
                The care of our residents is our primary concern so it is
                important that they are in constant contact with our staff. All
                heath care assistants and nurses carry phones on which the residents can
                contact staff using the bell attached to the phone in their
                room.
              </p>

             <div className="grid items-start max-w-screen-xl gap-8 fluid-container lg:grid-cols-3 md:grid-cols-1" style={{marginBottom}}>
                  <a data-lightbox href="assets/images/Marne-Street-Hospital_0059.jpg" className="block aspect-w-16 aspect-h-9">
                    <img src="assets/images/Marne-Street-Hospital_0059.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
                  </a>
                  <a data-lightbox href="assets/images/Marne-Street-Hospital_0030.jpg" className="block aspect-w-16 aspect-h-9 ">
                    <img src="assets/images/Marne-Street-Hospital_0030.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl " />
                  </a>
                  <a data-lightbox href="assets/images/Marne-Street-Hospital_0037.jpg" className="block aspect-w-16 aspect-h-9">
                    <img src="assets/images/Marne-Street-Hospital_0037.jpg" alt="" className="object-cover transition transform rounded-xl hover:scale-105 hover:shadow-2xl" />
                  </a>
                </div>
              </div>
             
            </div>
          </section>
        </main>
      </>
    );
  }
}
