import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './index.module.scss'


export class Nav extends React.Component<any> {

  constructor(props: any) {
    super(props)
  }


  render() {
    return (
      <div id="nav-primary" className="w-full">
        <div className="w-full shadow-2xl bg-primary">
          <div className="relative flex flex-row mx-4 md:fluid-container">
            <div className="absolute top-0 z-20 flex">
              <div className="flex flex-col xl:mx-auto">
                <a href="/" className="p-3 transition-all duration-500 transform bg-white pointer-events-auto hover:pt-6 bg-darker hover:bg-neutral-100" 
                  style={{
                    clipPath: "polygon(0% 0%, 100% 0%, 100% calc(100% - 16px), 50% 100%, 0% calc(100% - 16px))"
                  }}>
                  <img className="w-32 lg:w-48 " src="assets/images/marne-logo-primary.svg"/>
                </a>
                <div className="relative w-full h-6 z-behind bg-secondary -top-5" 
                  style={{
                    clipPath: "polygon(0% 0%, 100% 0%, 100% calc(100% - 16px), 50% 100%, 0% calc(100% - 16px))"
                  }}></div>
              </div>
            </div>
            <div className="hidden p-4 py-2 ml-auto font-sans text-white lg:inline-block">
              <a className="transition hover:text-secondary-light" href="tel:034542680">03 454 2680</a><span className="px-4 opacity-20">|</span><a className="transition hover:text-secondary-light" href="mailto:manager@marnestreet.co.nz">manager@marnestreet.co.nz</a>
            </div>
            <a href="#mobile-menu" className="self-center ml-auto text-white lg:hidden mburger">
              <b></b>
              <b></b>
              <b></b>
            </a>
          </div>
        </div>

        <div className="w-full shadow-2xl shadow-2x bg-secondary">
          <nav className="flex flex-row w-full mx-auto fluid-container">
            <ul className="flex-row items-center hidden ml-auto lg:flex">
              <li><NavLink className="link" to="/" exact>About Us</NavLink></li>
              <li><NavLink className="link" to="/clinical-care">Clinical Care</NavLink></li>
              <li><NavLink className="link" to="/facilities">Facilities</NavLink></li>
              <li><NavLink className="link" to="/our-team">Our Team</NavLink></li>
              <li><NavLink className="link" to="/family">Family</NavLink></li>
              <li><NavLink className="link" to="/gallery">Gallery</NavLink></li>
              <li><NavLink className="link" to="/faq">FAQ</NavLink></li>
              <li><NavLink className="link" to="/careers">Careers</NavLink></li>
              <li><NavLink className="link" to="/contact">Contact</NavLink></li>
            </ul>
          </nav>
        </div>
      </div>
    )
  }

}
