import React from 'react'

import 'mmenu-js';
import { NavLink } from 'react-router-dom';


export class Footer extends React.Component<any> {

  constructor(props: any) {
    super(props)
  }

  componentDidMount() {
    console.log("Footer mounted")

    var int = setInterval(() => {
      var e = document.querySelector("#mobile-menu")
      if (!e) return;
      console.log("Found mobile-menu, executing Mmenu()")
      ;(window as any).mobileNav = new (window as any).Mmenu("#mobile-menu", {
        extensions: ["position-right", "pagedim-black", "shadow-page", "theme-dark"],
        navbars: [{
          "position": "top",
          "content": [
            "<div style='padding: 30px; max-height: 300px; width: 60%; margin: 0 auto'><img style='max-width: 100%; max-height: 100%' src='/assets/images/marne-logo-invert.svg'> </div>"
          ]
        }]
      });
      clearInterval(int)
    }, 50)
  }

  render() {
    return (
      <footer className="mt-auto">
        <footer className="mt-32">

        {/* <!-- The Wave --> */}
        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" className="w-full h-4 -mb-1 fill-current md:h-8 text-primary" viewBox="0 0 650 40">
          <use xlinkHref="#wave"></use>
        </svg>

        <div className="relative text-white bg-primary">

          {/* <!-- Footer Topper --> */}
          {window.location.pathname as string !== "/contact" &&
          <div className="max-w-screen-xl fluid-container">
            <div className="relative flex flex-col items-center justify-between w-full p-8 -mb-20 space-y-12 text-white md:p-12 md:px-16 lg:flex-row -top-20 bg-secondary rounded-2xl lg:space-x-12 lg:space-y-0">
              <div className="text-center lg:w-2/3 lg:text-left">
                <div className="prose text-white md:prose-lg max-w-none">If you or a loved one would like to visit Marne Street Hospital then please get in touch - we would love to show you around our facilities and discuss options.</div>
              </div>
              <div className="flex justify-end lg:ml-auto lg:w-1/3">
                <a href="/contact" className="inline-flex px-12 py-4 font-sans text-xl transition bg-white whitespace-nowrap hover:bg-primary hover:text-white text-secondary rounded-2xl hover:shadow-2xl">Contact Our Team</a>
              </div>
            </div>
          </div> }

          {/* <!-- Footer Primary --> */}
          <div className="max-w-screen-xl font-sans fluid-container">
            <div className="grid items-center gap-6 py-8 md:grid-cols-2">
              <div className="">
                <img src="assets/images/marne-logo-invert.svg" alt="" className="w-64 mx-auto md:mx-0"/>
              </div>
              <div className="text-center md:text-right">
                <a className="block text-xl font-bold" href="tel:03 454 2680">03 454 2680</a>
                <a className="block text-xl font-bold" href="mailto:manager@marnestreet.co.nz">manager@marnestreet.co.nz</a>
                <div className="pt-4">7 Marne St, Andersons Bay, Dunedin 9013</div>
              </div>
            </div>
            <hr className="border-white opacity-20" />
            <div className="grid py-6 text-center md:grid-cols-2">
              <div className="md:text-left">{`© ${new Date().getFullYear()} Marne Street Hospital | All Rights Reserved`}</div>
              <div className="transition opacity-50 md:text-right hover:opacity-100">Website by <a className="bold hover:text-secondary-light" href="https://www.bluetwist.co.nz/">Bluetwist</a> & designed by <a className="bold hover:text-secondary-light" href="https://astronaut.nz">Astronaut</a></div>
            </div>
          </div>

        </div>
        </footer>

        {/* <!-- Mobile Nav Code --> */}
        <nav id="mobile-menu">
          <ul>
            <li><NavLink to="/">Home</NavLink></li>
            <li><NavLink to="/clinical-care">Clinical Care</NavLink></li>
            <li><NavLink to="/facilities">Facilities</NavLink></li>
            <li><NavLink to="/careers">Careers</NavLink></li>
            <li><NavLink to="/our-team">Our Team</NavLink></li>
            <li><NavLink to="/family">Family</NavLink></li>
            <li><NavLink to="/gallery">Gallery</NavLink></li>
            <li><NavLink to="/faq">FAQ</NavLink></li>
            <li><NavLink to="/contact">Contact</NavLink></li>
          </ul>
        </nav>  

        {/* <!-- The Wave SVG Definition --> */}
        <svg xmlns="http://www.w3.org/2000/svg" className="hidden">
        <defs>
          <symbol id="wave">
            <path d="M0 20q25-20 50 0t50 0 50 0 50 0 50 0 50 0 50 0 50 0 50 0 50 0 50 0 50 0 50 0 0 20T0 40" />
          </symbol>
          <symbol id="header-curve">
            <path d="M 0 0 L 0  200 L 180 200 Q 200 100 200 0" />
          </symbol>
        </defs>
        </svg>
      </footer>
    )
  }

}
