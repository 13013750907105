
import { Nav } from "components/Nav";
import { PageBase } from "pages/PageBase";

export class FaqPage extends PageBase {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <>
        <header className="">
          <Nav />

          {/* <!-- Hero Content --> */}
          <div className="relative bg-primary-dark">
            <div className="inset-y-0 top-0 right-0 w-full h-full lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
              <img
                src="assets/images/Marne-Street-Hospital_0003.jpg"
                alt=""
                className="inset-y-0 top-0 left-0 object-cover w-full h-full max-h-96 lg:max-h-screen"
              />
            </div>
            <div className="relative py-12 lg:py-24 fluid-container bg-primary-dark header-clip lg:min-h-[400px] flex flex-col justify-center">
              <div className="max-w-xl lg:w-2/5">
                <h1 className="text-3xl font-bold text-white md:text-5xl">
                  Frequently Asked Questions
                </h1>
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              className="absolute bottom-0 w-full h-4 -mb-1 text-white fill-current md:h-8"
              viewBox="0 0 650 40"
            >
              <use xlinkHref="#wave"></use>
            </svg>
          </div>
        </header>

        {/* <!-- Main Content Begins--> */}
        <main>
          {/* <!-- FAQ Content Section Block --> */}

          <section className="my-16">
            <div className="grid max-w-screen-xl gap-8 lg:gap-20 lg:grid-cols-2 fluid-container">
              <div className="prose prose-lg max-w-none pt-40">
                <h2>Frequently Asked Questions</h2>
                <p>
                  If you have any further questions that we have not answered
                  please don’t hesitate to contact us.
                </p>
              </div>

              <div className="w-full p-[13%] mt-6">
                <div className="relative w-full aspect-w-4 aspect-h-3">
                  <div className="absolute w-full">
                    <div className="block aspect-w-5 aspect-h-3">
                      <div className="block bg-black/10 rounded-2xl"></div>
                    </div>
                  </div>
                  <div className="absolute w-full transform translate-x-[-20%] translate-y-[-20%]">
                    <div className="block aspect-w-5 aspect-h-3">
                      <img
                        src="assets/images/Marne-Street-Hospital_0021.jpg"
                        alt=""
                        className="object-cover shadow-2xl rounded-2xl bg-primary"
                      />
                    </div>
                  </div>
                  <div className="absolute w-full translate-x-[20%] translate-y-[20%]">
                    <div className="block aspect-w-5 aspect-h-3">
                      <img
                        src="assets/images/Marne-Street-Hospital_0017.jpg"
                        alt=""
                        className="object-cover shadow-2xl rounded-2xl bg-primary"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="prose-lg px-8 fluid-container max-w-screen-xl" style={{ marginTop: "-5.5em" }}>
              <h3>Activities</h3>
              <p>
                <strong>
                  What is there for residents to do at Marne Street Hospital?
                </strong>
              </p>
              <p>
                <i>
                  A varied and interesting programme of activities is arranged
                  by our diversional therapist, which residents may take part in
                  as they choose.
                </i>
              </p>
              <h3>Alarms</h3>
              <p>
                <strong>How do I call for help?</strong>
              </p>
              <p>
                <i>
                  Every room has a call bell, as do our bathrooms, lounges and
                  dining area. All our staff carry phones which are connected to
                  the call bells so they can be contacted any time you require
                  assistance.
                </i>
              </p>
              <h3>Alcohol</h3>
              <p>
                <strong>Is alcohol allowed?</strong>
              </p>
              <p>
                <i>
                  Yes, and we provide wine, beer and spirits at set times and on
                  special occasions.
                </i>
              </p>
              <h3>Assessments</h3>
              <p>
                <strong>
                  How can I get a family member into Marne Street Hospital?
                </strong>
              </p>
              <p>
                <i>
                  A needs assessor appointed by the Te Whatu Ora / Health NZ will determine whether Marne Street Hospital is suitable
                  for your family member. Full details about the assessment
                  process can be found on the&nbsp;
                </i>
                <a href="http://www.marnestreet.co.nz/%5Bsitetree_link%20id=6%5D">
                  <i>Admission Process</i>
                </a>
                <i> page.</i>
              </p>
              <h3>Assistance</h3>
              <p>
                <strong>
                  Will there be someone to help me with showering, toileting and
                  dressing?
                </strong>
              </p>
              <p>
                <i>
                  Yes, we will help you with any personal assistance you require
                  at any time you need it.
                </i>
              </p>
              <p>
                <strong>What do I do if I need help at night?</strong>
              </p>
              <p>
                <i>
                  At least three staff members are on duty overnight and can be
                  summoned by using the call bell in your room.
                </i>
              </p>
              <h3>Church</h3>
              <p>
                <strong>Are church services available?</strong>
              </p>
              <p>
                <i>
                  Yes, we hold weekly services with each of the main
                  denominations taking a turn.
                </i>
              </p>
              <h3>Cost</h3>
              <p>
                <strong>How much does it cost to be a resident?</strong>
              </p>
              <p>
                <i>
                  This depends on whether you are eligible for a Residential
                  Care Subsidy from Work and Income. All the information you
                  need to know about this can be found in the&nbsp;
                </i>
                <a href="http://www.moh.govt.nz/moh.nsf/pagesmh/10203/$File/long-term-residential-care-older-people-2010.pdf">
                  <i>Ministry of Health Download</i>
                </a>
                <i>.</i>
              </p>
              <h3>Doctor</h3>
              <p>
                <strong>Will my own doctor continue to look after me?</strong>
              </p>
              <p>
                <i>
                  If that is your wish, although we have resident doctors
                  available if you prefer.
                </i>
              </p>
              <h3>Exercise</h3>
              <p>
                <strong>
                  Will I have to give up my daily walk or other exercise?
                </strong>
              </p>
              <p>
                <i>
                  We encourage residents to keep up an exercise regime, whether
                  it is a walk around the facility or across the park – we will
                  have someone keep you company. Our activities programme has an
                  exercise component.
                </i>
              </p>
              <h3>Furniture</h3>
              <p>
                <strong>Can I bring my own armchair?</strong>
              </p>
              <p>
                <i>
                  Yes (providing it doesn’t create a health and Safety issue),
                  but you won’t need to we provide you your own comfortable
                  recliner chair on wheels enabling the less mobile, access to
                  all areas of the hospital.
                </i>
              </p>
              <p>
                <strong>
                  What furniture is provided and what do I need to bring with
                  me?
                </strong>
              </p>
              <p>
                <i>
                  Each room has an electric bed, a bedside table, an over-bed
                  table and a wardrobe. You are welcome to bring any other
                  personal items, including a TV. For safety reasons, we do
                  require that rooms are not over-filled.
                </i>
              </p>
              <h3>Hairdresser</h3>
              <p>
                <strong>How will I get my hair cut?</strong>
              </p>
              <p>
                <i>
                  Weekly, a hairdresser comes to Marne Street Hospital. We
                  have a specially set up salon for this purpose.
                </i>
              </p>
              <h3>Internet Access</h3>
              <p>
                <strong>Internet is available.</strong>
              </p>
              <p>
                <i>
                  Yes, via WIFI. Staff will provide your loved one with a
                  password allowing WIFI access to the internet.
                </i>
              </p>
              <h3>Laundry</h3>
              <p>
                <strong>How will I wash my clothes?</strong>
              </p>
              <p>
                <i>
                  We have an in-house laundry and will take care of all your
                  laundry. We can even arrange for items that need special care
                  to be dry cleaned, for a small charge. It is important that
                  all clothes are named.
                </i>
              </p>
              <h3>Mail</h3>
              <p>
                <strong>How will I get my mail?</strong>
              </p>
              <p>
                <i>
                  Mail is personally delivered to your room every day except
                  Sunday.
                </i>
              </p>
              <p>
              </p>
              <h3>Meals</h3>
              <p>
                <strong>Is breakfast eaten in the communal dining room?</strong>
              </p>
              <p>
                <i>
                  Those residents who wish to get up for breakfast may do so,
                  however many prefer to have breakfast in their rooms.
                </i>
              </p>
              <p>
                <strong>Will I like the food?</strong>
              </p>
              <p>
                <i>
                  We understand the importance of good food so residents have a
                  major input into meal planning. We also cater for residents
                  with particular dietary requirements, such as vegetarian or
                  gluten-free meals. The menu is prepared in conjunction with
                  dieticians to ensure a balanced healthy diet.
                </i>
              </p>
              <h3>Medication</h3>
              <p>
                <strong>
                  Who will ensure I take my pills at the right time?
                </strong>
              </p>
              <p>
                <i>
                  Our staff will make sure you take the correct pill at the
                  right time. We will draw up a treatment chart of your
                  medication requirements. All medication is overseen by our
                  registered nurses and your GP.
                </i>
              </p>
              <h3>Money</h3>
              <p>
                <strong>
                  Do subsidised residents receive any personal funds?
                </strong>
              </p>
              <p>
                <i>
                  Yes, Work and Income pay residents a fortnightly allowance
                  directly into the bank account of their choice.
                </i>
              </p>
              <p>
                <strong>What cash access facilities are available?</strong>
              </p>
              <p>
                <i>
                  So that you do not have to hold more than small amounts of
                  money in your room, we operate a resident account from which
                  we issue cash as needed.
                </i>
              </p>
              <p>
                <strong>How do I pay my personal bills or accounts?</strong>
              </p>
              <p>
                <i>
                  We have a resident account which can be used to pay your bills
                  if required.
                </i>
              </p>
              <h3>Nurses</h3>
              <p>
                <strong>Are your nurses registered?</strong>
              </p>
              <p>
                <i>
                  We employ only suitably qualified registered nurses.&nbsp;
                </i>
              </p>
              <h3>Outings</h3>
              <p>
                <strong>
                  Are residents allowed to come and go as they please?
                </strong>
              </p>
              <p>
                <i>
                  Certainly, we encourage it although for your safety we do
                  require you to sign in or out when leaving or returning to the
                  hospital.
                </i>
              </p>
              <h3>Physiotherapist</h3>
              <p>
                <strong>
                  Does Marne Street Hospital have a physiotherapist?
                </strong>
              </p>
              <p>
                <i>
                  Yes, we engage the services of a qualified and experienced
                  physiotherapist.
                </i>
              </p>
              <h3>Podiatrist</h3>
              <p>
                <strong>Does Marne Street Hospital have a podiatrist?</strong>
              </p>
              <p>
                <i>
                  A podiatrist visits the hospital regularly. Where the visit is
                  prescribed by a GP, there is no charge.
                </i>
              </p>
              <h3>Rooms</h3>
              <p>
                <strong>Can I have personal items in my room?</strong>
              </p>
              <p>
                <i>
                  We encourage residents to bring with them personal items they
                  wish to have near them. This can include small items of
                  furniture, electronic equipment including TVs, photos, toys and plants.
                </i>
              </p>
              <p>
                <strong>Can I hang pictures in my room?</strong>
              </p>
              <p>
                <i>
                  Yes, and we have a maintenance person who will do this for
                  you.
                </i>
              </p>
              <h3>Smoking</h3>
              <p>
                <strong>Is smoking allowed?</strong>
              </p>
              <p>
                <i>
                  Only in outside designated areas and at designated times. Like
                  all public buildings in New Zealand, smoking is not permitted
                  indoors.
                </i>
              </p>
              <h3>Snacks</h3>
              <p>
                <strong>What if I get hungry between meals?</strong>
              </p>
              <p>
                <i>
                  We provide morning and afternoon teas, and suppers each day,
                  all with fresh home baking. If you require additional snacks
                  you just need to ask.
                </i>
              </p>
              <p>
                <strong>Is tea and coffee available?</strong>
              </p>
              <p>
                <i>
                  Yes, you or your family may make your own tea or coffee at any
                  time, or you can have a staff member make it for you.
                </i>
              </p>
              <h3>Staff</h3>
              <p>
                <strong>Is there continuity of staff?</strong>
              </p>
              <p>
                <i>
                  Our staff like to get to know the residents so wherever
                  possible the same team members will look after you each shift.
                  We have a number of staff who have worked here for several
                  years.
                </i>
              </p>
              <h3>Staying overnight</h3>
              <p>
                <strong>Can family members stay overnight?</strong>
              </p>
              <p>
                <i>
                  Yes, we have fold-out beds for any family member wishing to be
                  with their loved one should circumstances require it.
                </i>
              </p>
              <p>
              </p>
              <h3>Telephones</h3>
              <p>
                <strong>How do I make or receive telephone calls?</strong>
              </p>
              <p>
                <i>
                  We have a phone expressly for the use of residents. If you
                  wish, a phone can be installed in your room but rental charges
                  will apply. The phone in your room which also acts as your
                  call bell can receive outside calls
                </i>
              </p>
              <h3>Visiting</h3>
              <p>
                <strong>When are visiting hours?</strong>
              </p>
              <ul>
                <li>
                The time spent with a loved one is extremely special. At Marne Street Hospital, the family members of our residents are as much a part of our life as the resident themselves. 
                We encourage family members to share the life of their loved one at Marne Street Hospital by visiting and participating in the many activities provided.
                </li>
                <li>
                Covid-19 has led to Marne Street Hospitals’ management having to review and change the visiting hours policy on a semi regular basis.  
                This is necessary to comply with aged care industry’s best practices and government mandates.
                </li>
              </ul>
              <p>
              Please enquire at Marne Street Hospital reception <u><a href="mailto:reception@marnestreet.co.nz"> (reception@marnestreet.co.nz</a></u> 0r <u><a href="tel:034542680"> 03-4542680)</a></u> for our current visiting hours policy.
              </p>
            </div>
          </section>
        </main>

        
      </>
    );
  }
}
