import { Footer } from "components/Footer";
import { Nav } from "components/Nav";
import { PageBase } from "pages/PageBase";
import React from "react";

import styles from "./index.module.scss";

export class FamilyPage extends PageBase {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <>
        <header className="">
          <Nav />

          {/* <!-- Hero Content --> */}
          <div className="relative bg-primary-dark">
            <div className="inset-y-0 top-0 right-0 w-full h-full lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
              <img
                src={"assets/images/Marne-Street-Hospital_0030.jpg"}
                alt=""
                className="inset-y-0 top-0 left-0 object-cover w-full h-full max-h-96 lg:max-h-screen"
              />
            </div>
            <div className="relative py-12 lg:py-24 fluid-container bg-primary-dark header-clip lg:min-h-[400px] flex flex-col justify-center">
              <div className="max-w-xl lg:w-2/5">
                <h1 className="text-3xl font-bold text-white md:text-5xl">
                  Family Support
                </h1>
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              className="absolute bottom-0 w-full h-4 -mb-1 text-white fill-current md:h-8"
              viewBox="0 0 650 40"
            >
              <use xlinkHref="#wave"></use>
            </svg>
          </div>
        </header>
        {/* <!-- Main Content Begins--> */}
        <main>
          {/* <!-- Content Section Block --> */}

          <section className="my-16">
            {/* <div className="grid max-w-screen-xl gap-8 md:grid-cols-2 fluid-container md:gap-16"> */}
            <div className="grid max-w-screen-xl gap-8 lg:gap-20 lg:grid-cols-2 grid-rows-1 fluid-container my-8">
              {/* <!-- Primary Column --> */}
              <div className="prose prose-lg max-w-none">
                <h2>Families are Important too!</h2>
                <h3>Visiting Hours</h3>
                <p>
                The time spent with a loved one is extremely special. At Marne Street Hospital, the family members of our residents are as much a part of our life as the resident themselves. 
                We encourage family members to share the life of their loved one at Marne Street Hospital by visiting and participating in the many activities provided.
                </p>
                <p>
                Covid-19 has led to Marne Street Hospitals’ management having to review and change the visiting hours policy on a semi regular basis.
                This is necessary to comply with aged care industry’s best practices and government mandates.
                </p>
                <p>
                Please enquire at Marne Street Hospital reception<br></br> 
                <u><a href="mailto:reception@marnestreet.co.nz"> (reception@marnestreet.co.nz</a></u> 0r <u><a href="tel:034542680"> 03-4542680)</a></u> for our current visiting hours policy.
                </p>
                <h3>Family</h3>
                <p>
                We understand that placing your loved one into a residential facility is not an easy thing to do. There are a lot of emotions that go with handing over the care of your loved one. 
                We make it a priority to work through these concerns with you.</p>
                <p>
                Sometimes it is difficult for family members to discuss aspects of aging with their loved one. For this reason, we have produced several information booklets that can assist you to make decisions
                </p>
                <p>
                If you have any issues, both our facility manager and clinical manager encourage you to discuss these with them at the earliest opportunity so that your concerns can be addressed.                </p>
                
              </div>
              {/* column 2 - images */}
              <div className="w-full p-[13%] mt-6">
                <div className="relative w-full aspect-w-4 aspect-h-3">
                  <div className="absolute w-full">
                    <div className="block aspect-w-5 aspect-h-3">
                      <div className="block bg-black/10 rounded-2xl"></div>
                    </div>
                  </div>
                  <div className="absolute w-full transform translate-x-[-20%] translate-y-[-20%]">
                    <div className="block aspect-w-5 aspect-h-3">
                      <img
                        src="assets/images/Marne-Street-Hospital_0046.jpg"
                        alt=""
                        className="object-cover shadow-2xl rounded-2xl bg-primary"
                      />
                    </div>
                  </div>
                  <div className="absolute w-full translate-x-[20%] translate-y-[20%]">
                    <div className="block aspect-w-5 aspect-h-3">
                      <img
                        src="assets/images/Marne-Street-Hospital_0037.jpg"
                        alt=""
                        className="object-cover shadow-2xl rounded-2xl bg-primary"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
               <div className="prose-lg px-8 fluid-container max-w-screen-xl"> 
                    <p>
                     
                    </p>
              </div>
          </section>
        </main>
        
      </>
    );
  }
}
